
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.leaflet-container {
  height: 400px;
  width: 100%;
}

@media (min-width: 1281px) {
    * {
        box-sizing: border-box;
      }
      .contactHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Futura;
        font-size: xx-large;
        color: #2196F3;
    }
    .contactAddress{
        position: fixed;
        bottom: 50%;
        min-width: 100%;
        right: 0;
        /* background: rgb(235, 231, 231,0.1); */
        width: 100%;
        padding: 89px;
        font-size: 73px;
        color: white;
        justify-content: flex-start;
        align-items: center;
        display: flex;

    }
    .contactVid{
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top: 90;
    width: 1000px;
        height: 500px;
    }

    .contactImage{
        background-image: url('../../assets/twohand.jpg');
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }


  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {

    * {
        box-sizing: border-box;
      }
      .contactHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Futura;
        font-size: xx-large;
        color: #2196F3;
    }
    .contactAddress{
        position: fixed;
        bottom: 50%;
        min-width: 100%;
        right: 0;
        /* background: rgb(235, 231, 231,0.1); */
        width: 100%;
        padding: 89px;
        font-size: 73px;
        color: white;
        justify-content: flex-start;
        align-items: center;
        display: flex;

    }
    .contactVid{
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top:90;
    width: 1000px;
        height: 500px;
    }

    .contactImage{
        background-image: url('../../assets/twohand.jpg');
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }

  }

  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) {
    * {
        box-sizing: border-box;
      }
      .contactHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Futura;
        font-size: xx-large;
        color: #2196F3;
    }
    .contactAddress{
        position: fixed;
        bottom: 50%;
        min-width: 100%;
        right: 0;
        /* background: rgb(235, 231, 231,0.1); */
        width: 100%;
        padding: 89px;
        font-size: 73px;
        color: white;
        justify-content: flex-start;
        align-items: center;
        display: flex;

    }
    .contactVid{
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top:90;
    width: 1000px;
        height: 500px;
    }

    .contactImage{
        background-image: url('../../assets/twohand.jpg');
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }

  }

  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    * {
        box-sizing: border-box;
      }
      .contactHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Futura;
        font-size: xx-large;
        color: #2196F3;
    }
    .contactAddress{
        position: fixed;
        bottom: 50%;
        min-width: 100%;
        right: 0;
        /* background: rgb(235, 231, 231,0.1); */
        width: 100%;
        padding: 89px;
        font-size: 73px;
        color: white;
        justify-content: flex-start;
        align-items: center;
        display: flex;

    }
    .contactVid{
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top:90;
    width: 1000px;
        height: 500px;
    }

    .contactImage{
        background-image: url('../../assets/twohand.jpg');
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }


  }

  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

  @media (min-width: 481px) and (max-width: 767px) {
    * {
        box-sizing: border-box;
      }
      .contactHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Futura;
        font-size: xx-large;
        color: #2196F3;
    }
    .contactAddress{
        position: fixed;
        bottom: 50%;
        min-width: 100%;
        right: 0;
        /* background: rgb(235, 231, 231,0.1); */
        width: 100%;
        padding: 89px;
        font-size: 73px;
        color: white;
        justify-content: flex-start;
        align-items: center;
        display: flex;

    }
    .contactVid{
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top:90;
    width: 1000px;
        height: 500px;
    }

    .contactImage{
        background-image: url('../../assets/twohand.jpg');
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }


  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 480px) {

    * {
        box-sizing: border-box;
      }
      .contactHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Futura;
        font-size: xx-large;
        color: #2196F3;
    }
    .contactAddress{
        position: fixed;
        bottom: 50%;
        min-width: 100%;
        right: 0;
        /* background: rgb(235, 231, 231,0.1); */
        width: 100%;
        padding: 89px;
        font-size: 73px;
        color: white;
        justify-content: flex-start;
        align-items: center;
        display: flex;

    }
    .contactVid{
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top:90;
    width: 1000px;
        height: 500px;
    }

    .contactImage{
        background-image: url('../../assets/twohand.jpg');
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
    }


  }
