/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

.leaflet-container {
  height: 400px;
  width: 100%;
  top: 0;
  bottom: 0;
  position: relative;
}

.bodyContent{
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  @media (max-width:762px){
  }
}

.techTitle{
  margin-left: 20px;
  color: green;
  font-size: 40px;
}


.solutionImg {
  opacity: 0.9;
  @media (min-width: 360px) and (max-width: 600px) {
    height: 400px;
    margin-top: 3px;
  }
  @media (max-width: 1024px) and (min-width: 768px){
    height: 400px;
    margin-top: 93px;
  }

  @media (min-width: 1281px){
    height: 400px;
    margin-top: 45px;
  }
  width: 100%;
  margin-top: 103px;
  object-fit: cover;
  margin-bottom: 10px;
  position: relative;

}



.soltuionText_1{
  font-size: 52px;
  @media (max-width: 480px) and (min-width: 320px){
    font-size: 32px;

  }
}
.soltuionText_2{
  font-size: 72px;
  @media (max-width: 480px) and (min-width: 320px){
    font-size: 32px;

  }
}

.soltuionText{
  position: absolute;
  left: 5;
  margin-left: 20px;
  top: 150px;
  color: #F4F4F4;
  font-family: Raleway;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 80px;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.8);
    @media (min-width: 320px) and (max-width: 480px) {
      top: 200px;
    }
  }

.clsTechnology{
  object-fit: cover;
width: 100%;
height: 300px;
margin-top: 200px;
}

