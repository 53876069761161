$break-big:1279px;
$break-small: 320px;
$break-7x:758px;

.burgerMenu{
    position: relative;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width : $break-big) {

        display: none;
     }
    

}

.headerMenu{
    position: absolute;
    right: 0;
    @media (max-width: $break-big) {

       display: none;
    }
}

.mainHeader{
    margin-top: 30px;
}


.logo{
    position: absolute;
    left: 20px;
    width: 40px;
    height: 40px;
}

.title{
    display: flex;
    position: absolute;
    left:80px;
    color: green;
    font-family: Futura;
    font-size: larger;

}
